<template>
  <div class="container-fluid">
    <div class="row">
      <div style="clear: both"></div>
      <div
        class="col-sm-12 col-md-12 p-0"
        style="
          background-color: #eceff0;
          height: auto;
          border-left: 1px solid #7d7d7d;
          overflow-y: auto;
          height: 93vh;
          position: relative;
        "
      >
        <div :style="`overflow-y: auto; height: ${screenHight - chatContentHeight}px`">
          <div
            v-for="(supportChatData, index) in feedbackChat"
            :key="supportChatData + index"
          >
            <div
              class="text-left"
              style="display: flex; padding: 20px !important; width: 100%"
              v-if="
                userId !== supportChatData.user_created.id
              "
            >
              <!-- Avatar -->
              <div>
                <avatar
                  v-if="supportChatData.user_created.url_avatar"
                  :src="supportChatData.user_created.url_avatar"
                  backgroundColor="#fff"
                  color="#000"
                  :size="40"
                  style="align-self: start"
                ></avatar>
                <avatar
                  v-else
                  :username="supportChatData.fullname"
                  backgroundColor="#fff"
                  color="#000"
                  :size="40"
                  style="align-self: start"
                ></avatar>
              </div>
              <!-- Message -->
              <div
                style="
                  align-self: center;
                  margin-right: 10px;
                  padding: 5px 10px 5px 10px;
                  background-color: #fff;
                  border-radius: 5px;
                  margin-left: 10px;
                "
              >
                <span
                  style="display: block; color: #7d7d7d"
                  class="meeting-font-medium"
                  >{{ supportChatData.fullname }}</span
                >
                <span
                  style="display: block; color: #7d7d7d; font-size: 13px"
                  class="font-italic"
                  >{{ supportChatData.position }}</span
                >
                <span class="meeting-font-medium">{{
                  supportChatData.content
                }}</span>
              </div>
            </div>
            <div
              v-else
              class="text-right mt-2"
              style="
                padding: 20px !important;
                float: right;
                display: flex;
                clear: both;
              "
            >
              <!-- Message -->
              <div
                style="
                  align-self: center;
                  margin-right: 10px;
                  padding: 5px 10px 5px 10px;
                  background-color: #26ba50;
                  border-radius: 5px;
                  color: #fff;
                "
              >
                <span
                  style="display: block; color: #ffffff"
                  class="meeting-font-medium"
                  >{{ supportChatData.fullname }}</span
                >
                <span
                  style="display: block; color: #ffffff; font-size: 13px"
                  class="font-italic"
                  >{{ supportChatData.position }}</span
                >
                <span class="meeting-font-medium" style="text-align: left !important; float: left;">{{
                  supportChatData.content
                }}</span>
              </div>
              <!-- Avatar -->
              <div>
                <avatar
                  v-if="supportChatData.user_created.url_avatar"
                  :src="supportChatData.user_created.url_avatar"
                  backgroundColor="#26BA50"
                  color="#fff"
                  :size="40"
                  style="align-self: start"
                ></avatar>
                <avatar
                  v-else
                  :username="supportChatData.fullname"
                  backgroundColor="#26BA50"
                  color="#fff"
                  :size="40"
                  style="align-self: start"
                ></avatar>
              </div>
            </div>
          </div>
        </div>
        <!-- <div style="clear: both"></div> -->
        <div
          id="feedbackInput"
          ref="feedbackInput"
          style="
            display: flex;
            margin-top: 20px;
            background-color: #fff;
            position: absolute;
            width: 100%;
            bottom: 0;
          "
        >
          <!-- Like icon -->
          <div
            style="margin-left: 20px; align-self: center; cursor: pointer"
            @click="onClickSendMesseger($t('support.thanksMessages'))"
          >
            <img src="../../assets/icons/like.svg" width="20px;" />
          </div>
          <div style="width: 100%">
            <input
              type="text"
              :placeholder="$t('feedback.feedbackContent')"
              style="width: 100%; border: none; padding: 20px"
              class="meeting-font-medium"
              v-model="contentSupportChat"
              v-on:keyup.enter="postSupportChat"
            />
          </div>
          <!-- Send icon -->
          <div
            style="
              float: right;
              margin-right: 20px;
              align-self: center;
              cursor: pointer;
            "
            @click="postSupportChat"
          >
            <img src="../../assets/icons/send.svg" width="20px;" />
          </div>
        </div>
      </div>
    </div>
    <snack-bar
      v-for="snackbar in GET_SNACK_BAR"
      :key="snackbar.id"
      :item="snackbar"
    ></snack-bar>
  </div>
</template>
<script>
import Avatar from "vue-avatar";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    avatar: Avatar,
  },
  data: () => ({
    roomMeetingSelected: 1,
    options: [],
    contentSupport: "",
    event_id: 0,
    supportList: [],
    showSupport: 0,
    contentSupportChat: "",
    supportSelect: "",
    feedbackChat: [],
    supportCalendarUI: false,
    screenHight: 0,
    chatContentHeight: 0
  }),
  watch: {
    GET_FEEDBACK_LIST_DATA() {
      this.feedbackChat = [];
      this.GET_FEEDBACK_LIST_DATA.forEach((data) => {
        let item = {
          id: data.id,
          content: data.content,
          user_created: data.user_create_data,
          fullname: data.user_create_data.last_name + ' ' + data.user_create_data.first_name,
          position: data.user_create_data.company_position
        };
        this.feedbackChat.push(item);
      });
    },
    CALLBACK_API_FEEDBACK: function () {
      if (this.$route.name === 'sideBar.feedback'){
        this.getFeedbackList()
      }
    }
  },
  computed: {
    userId: function() {
      return this.GET_CURRENT_ACCOUNT.id
    },
    ...mapGetters([
      "GET_SNACK_BAR",
      "GET_FEEDBACK_LIST_DATA",
      "GET_CURRENT_ACCOUNT",
      "CALLBACK_API_FEEDBACK"
    ]),
  },
  methods: {
    showInfoSupport(index, item) {
      this.showSupport = index;
      this.supportSelect = item != null ? item.id : null;
      let support_id = {
        params: {
          support_id: this.supportSelect,
        },
      };
      this.GET_SUPPORT_CHAT(support_id);
    },
    postSupportChat() {
      if (this.contentSupportChat == "" || this.contentSupportChat == null) {
        this.ON_SHOW_TOAST({
          message:
            this.$t("common.inputFieldNull") +
            " " +
            this.$t("support.supportContentChat"),
          styleType: "danger",
        });
      } else {
        let data = {
          eventId: this.event_id,
          content: this.contentSupportChat,
        };
        this.CREATE_FEEDBACK(data)
          .then(
            function () {
              this.contentSupportChat = "";
              let data = {
                params: {
                  eventId: this.event_id
                },
              };
              this.GET_FEEDBACK_LIST(data);
              this.ON_SHOW_TOAST({
                message: "Success",
                styleType: "success",
              });
            }.bind(this)
          )
          .catch(
            function (error) {
              this.ON_SHOW_TOAST({
                message: error.response.data.message,
                styleType: "danger",
              });
            }.bind(this)
          );
      }
    },
    onClickSendMesseger(contentData) {
      let data = {
        support_id: this.supportSelect,
        content: contentData,
      };
      this.CREATE_FEEDBACK(data)
        .then(
          function () {
            this.contentSupportChat = "";
            let data = {
              params: {
                eventId: this.event_id
              },
            };
            this.GET_FEEDBACK_LIST(data);
            this.ON_SHOW_TOAST({
              message: "Success",
              styleType: "success",
            });
          }.bind(this)
        )
        .catch(
          function (error) {
            this.ON_SHOW_TOAST({
              message: error.response.data.message,
              styleType: "danger",
            });
          }.bind(this)
        );
    },
    getFeedbackList: function() {
      let filter = {
        params: {
          eventId: this.event_id
        },
      }
      this.GET_FEEDBACK_LIST(filter);
    },
    handleResize(){
      this.screenHight=window.innerHeight - 55 > 300 ? window.innerHeight - 55 : 300
    },
    ...mapActions([
      "ON_SHOW_TOAST",
      "GET_FEEDBACK_LIST",
      "CREATE_FEEDBACK",
      "GET_SUPPORT_CHAT",
    ]),
  },
  async mounted() {
    var elmnt = await document.getElementById('feedbackInput')
    this.chatContentHeight = elmnt.clientHeight
    let event_id = sessionStorage.getItem("event_id");
    if (event_id) {
      this.event_id = event_id;
      let filter = {
        params: {
          eventId: event_id,
        },
      };
      this.GET_FEEDBACK_LIST(filter);
    }
  },
  async created() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  }
};
</script>
<style scoped>
/* input:focus {
  outline: none;
} */
.support-title {
  display: flex;
  justify-content: space-between;
  padding: 10px 20px 10px 20px;
  cursor: pointer;
}
.select {
  background-color: #eceff0;
}
.not-select {
  border-bottom: 1px solid #c2c3c3;
}
</style>
